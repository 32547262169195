<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="商品评论配置" name="1">
                    <el-form class="form-box" ref="productForm" v-bind:rules="rules" v-bind:model="productForm" label-position="right" label-suffix=":" size="large">
                        <el-form-item prop="relation_keyword" label="关联业务方">
                            <el-select v-model="productForm.id" placeholder="请选择业务方" class="handle-select mr10">
                                <el-option v-for="items in businessList" :key="items.index" :label="items.name" :value="items.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="图片是否审核" prop="is_image">
                            <el-radio-group v-model="productForm.is_image">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="文字是否审核" prop="is_keyword">
                            <el-radio-group v-model="productForm.is_keyword">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开启评论" prop="is_comment">
                            <el-radio-group v-model="productForm.is_comment">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitProductForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="社区评论配置" name="2">
                    <el-form class="form-box" ref="communityForm" v-bind:rules="rules" v-bind:model="communityForm" label-position="right" label-suffix=":" size="large">
                        <el-form-item prop="relation_keyword" label="关联业务方">
                            <el-select v-model="communityForm.id" placeholder="请选择业务方" class="handle-select mr10">
                                <el-option v-for="items in businessList" :key="items.index" :label="items.name" :value="items.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="图片是否审核" prop="is_image">
                            <el-radio-group v-model="communityForm.is_image">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="文字是否审核" prop="is_keyword">
                            <el-radio-group v-model="communityForm.is_keyword">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开启评论" prop="is_comment">
                            <el-radio-group v-model="communityForm.is_comment">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitCommunityForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { request } from '@/common/request';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb
    },
    data() {
        return {
            businessList: [],
            activeName: '1',
            menuList: ['系统', '评论设置'],
            loading: false,
            rules: {
                relation_keyword: [{
                    required: true,
                    message: '关联业务方不能为空',
                    trigger: 'blur'
                }],
                is_content: [{
                    required: true,
                    message: '是否评论不能为空',
                    trigger: 'blur'
                }],
            },
            productForm: {
            },
            communityForm: {
            },
        }
    },
    created() {
        this.loadBusinessData()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            console.log(22222)
            switch (tab.paneName) {
                case '1':
                    this.loadProductData()
                    break
                case '2':
                    this.loadCommunityData()
                    break
            }
        },

        //业务方列表
        loadBusinessData() {
            request.get('/filter/business/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.businessList = ret.data.list;
                    console.log(44444)
                    this.loadProductData()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 商品评价
        loadProductData() {
            this.loading = true;
            request.get('/system/comment/goods').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.productForm = ret.data
                    // this.productForm.relation_keyword = ret.data.name
                    console.log(1111, this.productForm)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },


        // 保存商品评论设置
        submitProductForm() {
            this.loading = true;
            let params = Object.assign({}, this.productForm)
            params.relation_keyword = this.productForm.id
            console.log(3333, params)
            request.post('/system/comment/goods/edit', params).then(ret => {
                if (ret.code == 1) {
                    this.loadProductData()
                    this.loading = false;
                    this.$refs.productForm.clearValidate()
                    this.$message.success('商品评论保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 社区评价
        loadCommunityData() {
            this.loading = true;
            request.get('/system/comment/community').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.communityForm = ret.data
                    // this.communityForm.relation_keyword = ret.data.name
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存社区评论设置
        submitCommunityForm() {
            this.loading = true;
            console.log(444, this.communityForm)
            let params = Object.assign({}, this.communityForm)
            params.relation_keyword = this.communityForm.id
            request.post('/system/comment/community/edit', params).then(ret => {
                if (ret.code == 1) {
                    this.loadCommunityData()
                    this.loading = false;
                    this.$refs.communityForm.clearValidate()
                    this.$message.success('社区评论保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

    },
}

</script>

<style scoped>
.desc {
    margin-left: 10px;
    font-size: 14px;
    color: #606266;
}

.inline {
    display: inline-block;
    width: 25%;
}

.money >>> .el-input {
    width: 100px !important;
}

.icon_box {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #dddddd;
    margin-left: 10px;
    text-align: center;
    line-height: 30px;
}
.el-form-item--large.el-form-item {
    margin-top: 30px;
}
</style>